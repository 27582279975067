<template>
  <div>
    <operations-detail-component-global 
      :sApiGetOC="sApiGetOC" 
      :aHeadersRawMaterials="aHeadersRawMaterials"
      :sTypeItem="4" 
      sBackHref="CustomerDetail" 
      sTextDetail="" 
      setDialog="setDialogConfirmationOfPaid" 
      sTextBtnAdd=""
      :bAdminPermissions="false" 
      :itemsBreadcrumbs="itemsBreadcrumbs" 
      :toProvider="toProvider" 
      :bToProvider="false" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
      toProvider: "",
      sApiGetOC: "",
      aHeadersRawMaterials: [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Material",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Merma",
          sortable: false,
          value: "dUnprocessedWeight",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.setApiGetOC();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Clientes",
          disabled: false,
          to: { name: "Customer" },
        },
        {
          text: "Detalle del cliente",
          link: true,
          exact: true,
          disabled: false,
          to: { name: "CustomerDetail" },
          params: { id: this.$route.params.idOC },
        },
        {
          text: "Detalle de un pedido de venta",
          disabled: true,
          to: { name: "" },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.toProvider = `/home/customer-detail/${this.$route.params.id}/op-detail-customer/${this.$route.params.idOP}/user-profile-detail-customer`;
    },
    setApiGetOC() {
      this.sApiGetOC = `${URI}/api/sp/v1/orders/general/`;
    },
  },
  computed: {

  },
};
</script>

<style>
</style>